.form{
    margin-top: 2%;
}
.form .form_row{
    padding: 0 ;
}
.form .form-label{
    color: var(--main);
    font-size: 1.3rem;
    font-family: myfont;
}
.rtl .form .form-label{
    margin-right: 2%;
}
.ltr .form .form-label{
    margin-left: 2%;
}
.form .form-control{
    border: 2px solid var(--heavenly);
    border-radius: 20px 20px;
}
.form .form-control:focus{
    border: 2px solid var(--heavenly);
    box-shadow: none !important;
}
.form .massage{
    height: 25vh;
}
.form .btn2{
    background-color: var(--main) !important;
    color: #fff;
    width: 20%;
    padding-bottom: 6px;
    border-radius: 20px 20px;
    font-family: myfont;
    transition: all .5s;
    font-size: 1.2rem;
}
.form .btn2:hover{
    background-color: #fff !important;
    border: 2px solid var(--main) !important;
    color: var(--main) !important;
}
.swal2-container {
    position: fixed;
    z-index: 99999;
    width: 100%;
    height: 100%;
  }
  .ltr .recaptcha{
    direction: ltr;
  }
  .rtl .recaptcha{
    direction: rtl;
  }
  .form .error{
    color: red;
    padding-top: 1%;
  }
@media (max-width: 425px) {
    /* For mobile phones: */
    .form{
        margin-top: 2%;
    }
    .form .form_row{
        padding: 0 5%;
    }
    .form .massage{
        height: 12vh;
    }
  }
  @media (max-width: 768px) {
    /* For tablets: */
    .form{
        margin-top: 2%;
    }
    .form .massage{
        height: 18vh;
    }
  }
  
