.product {
  z-index: 11111;
  top: -7rem !important;
  border-radius: 8px;
  background-color: var(--white);
}
.about-left {
  position: relative;
}
.about .product {
  position: relative;
  min-height: 100vh;
}
.prod_title p {
  color: var(--gray);
}
.policy_paragraph {
  color: #4d4d4d !important;
  line-height: 2.3;
  font-size: 16px;
  font-weight: 300;
  font-family: myfont;
}
.paragraph span {
  font-weight: bold;
}

.about p {
  text-align: right;
  color: #635d5d;
  line-height: 2.2;
  font-size: 16px;
  opacity: 0.9;
}

.about .paragraph {
  padding: 0 !important;
}

.about .paragraph-header {
  font-size: 17px !important;
  opacity: 1 !important;
}

.medals_list .arrow {
  color: #29abe2;
}

.medals_list li {
  color: #635d5d;
}

.rtl .about .triangle_control {
  right: 54%;
  top: -1px;
}

.triangle-control-com-left {
  position: relative;
  right: 0.5rem;
}

.triangle-control-com-left .triangle-com {
  /* transform: rotate(90deg) !important; */
  transform: rotateY(180deg);
}

.rtl .m-right.aboutleft {
  margin-bottom: 5rem !important;
}

.triangle-control-com-left :first-child {
  left: 0rem !important;
  top: -2rem;
}

.rtl .triangle-control-com-left :last-child {
  top: -2rem;
  left: 1.7rem !important;
}

.ltr .triangle-control-com-left :last-child {
  top: -2rem;
  left: -0.7rem !important;
}

/* .rtl .triangle-control-com-left:hover { */
/* transform: scale(1.13) !important; */
/* transition: all .5s; */
/* box-shadow: rgba(255, 255, 255, 0.55) !important; */
/* } */
/* .ltr .triangle-control-com-left:hover :first-child { */
/* transform: scale(1.1) translate(-10px, -5px) !important; */
/* box-shadow: rgba(255, 255, 255, 0.55) !important; */
/* } */
/*  */
/* .ltr .triangle-control-com-left:hover :nth-child(2) { */
/* transform: scale(1.05) !important; */
/* box-shadow: rgba(255, 255, 255, 0.55); */
/* } */
.rtl .triangle-control-com-left {
  animation-name: triangle;
  animation-duration: 5s;
  animation-iteration-count: infinite;
}

.ltr .triangle-control-com-left {
  /* top:7.5rem; */
  animation-name: triangleleft;
  animation-duration: 5s;
  animation-iteration-count: infinite;
}

@keyframes triangleleft {
  0% {
    top: 7.3rem;
  }
  5% {
    top: 7rem;
  }
  10% {
    top: 6.7rem;
  }
  15% {
    top: 6.4rem;
  }
  20% {
    top: 6.1rem;
  }
  25% {
    top: 5.8rem;
  }
  30% {
    top: 6.1rem;
  }
  35% {
    top: 6.4rem;
  }
  40% {
    top: 6.7rem;
  }
  45% {
    top: 7rem;
  }

  50% {
    top: 7.3rem;
  }

  55% {
    top: 7rem;
  }

  60% {
    top: 6.7rem;
  }

  65% {
    top: 6.4rem;
  }

  70% {
    top: 6.1rem;
  }

  75% {
    top: 5.8rem;
  }

  80% {
    top: 6.1rem;
  }

  85% {
    top: 6.4rem;
  }

  90% {
    top: 6.7rem;
  }

  95% {
    top: 7rem;
  }

  100% {
    top: 7.3rem;
  }
}

.ltr .About .about-us .left .prod_title .map_text .triangle_control {
  left: 53% !important;
}

.ltr .About .about-us .right .prod_title .map_text .triangle_control {
  left: 52% !important;
}

.ltr .About .about-us .right .prod_title .map_text .triangle_control {
  left: 53% !important;
}

.ltr .About .ma-right .right .prod_title .map_text .triangle_control {
  left: 63% !important;
}

.ltr .About .aboutleft .left .prod_title .map_text .triangle_control {
  left: 72% !important;
}

.ltr .About .medals .left.modals .prod_title .map_text .triangle_control {
  left: 57% !important;
}

.ltr .left .triangle-control-com-left {
  position: absolute;
  right: 23rem !important;
}

/*  */
/*  */

.ltr .m_button {
  margin-bottom: 8rem !important;
}

.ltr .triangle-control-com-left :nth-child(1) {
  left: 1rem !important;
}

.ltr .triangle-control-com-left :nth-child(2) {
  left: -1rem;
}

@media (min-width: 577px) and (max-width: 992px) {
  .rtl .triangle-control-com-left {
    animation-name: trianglemedia;
  }

  .rtl .medals .triangle-control-com-left {
    animation-name: trianglemediamedals;
  }

  .ltr .triangle-control-com-left {
    animation-name: triangleleft !important;
  }

  @keyframes trianglemedia {
    0% {
      top: -36rem;
    }

    5% {
      top: -35.7rem;
    }

    10% {
      top: -35.4rem;
    }

    15% {
      top: -35.1rem;
    }

    20% {
      top: -34.8rem;
    }

    25% {
      top: -34.5rem;
    }

    30% {
      top: -34.8rem;
    }

    35% {
      top: -35.1rem;
    }

    40% {
      top: -35.4rem;
    }

    45% {
      top: -35.7rem;
    }

    50% {
      top: -36rem;
    }

    55% {
      top: -36.3rem;
    }

    60% {
      top: -36.6rem;
    }

    65% {
      top: -36.9rem;
    }

    70% {
      top: -37.2rem;
    }

    75% {
      top: -37.5rem;
    }

    80% {
      top: -37.2rem;
    }

    85% {
      top: -36.9rem;
    }

    90% {
      top: -36.6rem;
    }

    95% {
      top: -36.3rem;
    }

    100% {
      top: -36rem;
    }
  }

  @keyframes trianglemediamedals {
    0% {top: -50rem;}
    5% {top: -49.7rem;}
    10% {top: -49.4rem;}
    15% {top: -49.1rem;}
    20% {top: -48.8rem;}
    25% {top: -48.5rem; }
    30% {top: -48.8rem;}
    35% { top: -49.1rem;}
    40% { top: -49.4rem; }
    45% { top: -49.7rem; }
    50% { top: -50rem; }
    55% { top: -50.3rem; }
    60% { top: -50.6rem; }
    65% { top: -50.9rem; }
    70% { top: -51.2rem; }
    75% { top: -51.5rem; }
    80% { top: -51.2rem; }
    85% { top: -50.9rem; }
    90% { top: -50.6rem; }
    95% { top: -50.3rem; }
    100% { top: -50rem; }
  }

  /* For tablets: */
  .about_text {
    text-align: center;
    margin-top: 52%;
  }

  .rtl .m-right.aboutleft {
    margin-bottom: 0rem !important;
  }

  .ltr .About .about-us .right .prod_title .map_text .triangle_control {
    left: 56% !important;
  }

  .rtl .ma-right .about .prod_title .map_text .triangle_control {
    right: 66% !important;
  }

  .about-left {
    top: -5rem !important;
    right: 0rem !important;
  }

  .triangle-control-com-left {
    top: -37rem;
    right: calc(-50% + 13rem) !important;
  }

  .modals .triangle-control-com-left {
    top: -47rem;
  }

  .triangle-control-com-left :nth-child(2) {
    left: 2rem;
  }

  /* .ltr .triangle-control-com-left {
    top: 8rem !important;
  } */
}

@media (max-width: 768px) {
  .rtl .medals .triangle-control-com-left {
    animation-name: trianglemediamedals;
  }
  @keyframes trianglemediamedals {
    0% {top: -50rem;}
    5% {top: -49.7rem;}
    10% {top: -49.4rem;}
    15% {top: -49.1rem;}
    20% {top: -48.8rem;}
    25% {top: -48.5rem; }
    30% {top: -48.8rem;}
    35% { top: -49.1rem;}
    40% { top: -49.4rem; }
    45% { top: -49.7rem; }
    50% { top: -50rem; }
    55% { top: -50.3rem; }
    60% { top: -50.6rem; }
    65% { top: -50.9rem; }
    70% { top: -51.2rem; }
    75% { top: -51.5rem; }
    80% { top: -51.2rem; }
    85% { top: -50.9rem; }
    90% { top: -50.6rem; }
    95% { top: -50.3rem; }
    100% { top: -50rem; }
  }
  .rtl .m-right.aboutleft {
    margin-bottom: 0rem !important;
  }

  .about_text {
    text-align: center;
    margin-top: 52%;
  }

  .rtl .about-us .about .triangle_control {
    right: 52%;
    top: -1px;
  }

  .about-left {
    top: -5rem !important;
    right: 0rem !important;
  }

  .triangle-control-com-left {
    top: -37rem;
    right: calc(-50% + 13rem) !important;
  }

  .modals .triangle-control-com-left {
    top: -47rem;
  }

  .triangle-control-com-left :nth-child(2) {
    left: 2rem;
  }

  /* .ltr .triangle-control-com-left {
    top: 8rem !important;
  } */
  .about_text {
    text-align: center;
    margin-top: 52%;
  }

  .about-left {
    top: -5rem !important;
    right: 0rem !important;
  }

  .ltr .About .about-us .left .prod_title .map_text .triangle_control {
    left: 59% !important;
  }

  .ltr .About .about-us .right .prod_title .map_text .triangle_control {
    left: 59% !important;
  }

  .ltr .About .ma-right.aboutleft .right .prod_title .map_text .triangle_control {
    left: 73.5% !important;
  }

  .ltr .About .m-right.aboutleft .left .prod_title .map_text .triangle_control {
    left: 82% !important;
  }

  .ltr .About .m-right.medals .left .prod_title .map_text .triangle_control {
    left: 67% !important;
  }
}

@media (max-width: 576px) {
  .rtl .triangle-control-com-left {
    animation-name: trianglemedia;
  }

  .ltr .triangle-control-com-left {
    animation-name: triangleleft !important;
  }

  @keyframes trianglemedia {
    0% {
      top: -36rem;
    }

    5% {
      top: -35.7rem;
    }

    10% {
      top: -35.4rem;
    }

    15% {
      top: -35.1rem;
    }

    20% {
      top: -34.8rem;
    }

    25% {
      top: -34.5rem;
    }

    30% {
      top: -34.8rem;
    }

    35% {
      top: -35.1rem;
    }

    40% {
      top: -35.4rem;
    }

    45% {
      top: -35.7rem;
    }

    50% {
      top: -36rem;
    }

    55% {
      top: -36.3rem;
    }

    60% {
      top: -36.6rem;
    }

    65% {
      top: -36.9rem;
    }

    70% {
      top: -37.2rem;
    }

    75% {
      top: -37.5rem;
    }

    80% {
      top: -37.2rem;
    }

    85% {
      top: -36.9rem;
    }

    90% {
      top: -36.6rem;
    }

    95% {
      top: -36.3rem;
    }

    100% {
      top: -36rem;
    }
  }

  .ltr .triangle-control-com-left :nth-child(1) {
    left: 5rem !important;
    top: 0.5rem !important;
  }

  .rtl .m-right.aboutleft {
    margin-bottom: 0rem !important;
  }

  .ltr .triangle-control-com-left :nth-child(2) {
    left: 3rem !important;
    top: 0.5rem !important;
  }

  .ltr .About .about-us .left .prod_title .map_text .triangle_control {
    left: 66% !important;
  }

  .ltr .About .m-right.medals .left .prod_title .map_text .triangle_control {
    left: 81% !important;
  }

  .ltr .About .about-us .right .prod_title .map_text .triangle_control {
    left: 65% !important;
  }

  .ltr .About .ma-right.aboutleft .right .prod_title .map_text .triangle_control {
    left: 92% !important;
  }

  .ltr .About .m-right.aboutleft .left .prod_title .map_text .triangle_control {
    left: 110% !important;
  }

  .ltr .About .medals .left .prod_title .map_text .triangle_control {
    left: 82% !important;
  }
}

@media (max-width: 425px) {
  .rtl .m-right.aboutleft {
    margin-bottom: 0rem !important;
  }

  .about_text {
    margin-top: 79%;
  }

  .about_text {
    margin-top: 79%;
  }

  .about-left.about-page-triangle-bg .triangle {
    top: 21.4em !important;
    right: 8rem !important;
  }

  .about-left.about-page-triangle-bg .triangle,
  .about-left.about-page-triangle-bg .triangle:before,
  .about-left.about-page-triangle-bg .triangle:after {
    width: 13em;
    height: 13em;
    border: 2px 0px 1px 0;
  }

  .about-left {
    right: 1.5rem !important;
    top: -10rem !important;
  }

  .ltr .triangle-control-com-left :nth-child(1) {
    left: 7rem !important;
    top: 1rem;
  }

  .ltr .paragraph {
    margin-right: 0% !important;
  }

  .ltr .description2 {
    margin-left: 2% !important;
  }

  .ltr .left .triangle-control-com-left :nth-child(2) {
    left: 5rem !important;
    top: 1rem;
  }

  .ltr .About .about-us .right .prod_title .map_text .triangle_control {
    left: 65% !important;
  }

  .ltr .About .ma-right .right .prod_title .map_text .triangle_control {
    left: 99.8% !important;
  }

  .ltr .About .about-us .left .prod_title .map_text .triangle_control {
    left: 67% !important;
  }

  .ltr .About .m-right.aboutleft .left .prod_title .map_text .triangle_control {
    left: 114% !important;
  }

  .ltr .About .medals .left.modals .prod_title .map_text .triangle_control {
    left: 83% !important;
  }

  .about-left {
    right: 1.5rem !important;
    top: -10rem !important;
  }

  .ltr .About .right,
  .ltr .About .left {
    text-align: left !important;
  }
}

@media (max-width: 380) {
  .ltr .triangle-control-com-left :nth-child(1) {
    top: 10rem !important;
  }

  .rtl .m-right.aboutleft {
    margin-bottom: 0rem !important;
  }
}