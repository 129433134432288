.product_card {
  width: 99% !important;
  height: 45vh !important;
  border: none !important;
  border-radius: 9px !important;
  box-shadow: 1px 1px 10px var(--gray);
}
.card.product_card:hover {
  box-shadow: 5px 5px 30px #29abe2;
  transform: scale(1.05) !important;
  transition: all 0.5s;
}
.product_container .product .product_row .card .img {
  min-width: 10vw !important;
  min-height: 30vh !important;
  overflow: visible;
  cursor: pointer;
}
.product_row .card .img img {
  width: 100% !important;
  height: 100%;
}
.card.product_card:hover img {
  transform: scale(1.1) !important;
  transition: all 0.8s;
}

.card h6 {
  color: var(--main);
  font-size: 1.1rem;
  width: fit-content;
  margin: 0 auto;
}
.product_row .card .product_btn {
  background-color: var(--heavenly);
  border: none;
  width: 50%;
  height: 15%;
  padding-bottom: 2%;
  margin-left: 50%;
  border-radius: 15px 0 9px 0;
  color: var(--main);
  font-size: 1.2rem;
  font-weight: 300 !important;
}
.product .product_description,
.product .product_description p {
  color: var(--gray);
  width: 85%;
  text-align: right;
  font-family: myfont;
  font-size: 0.9rem;
  margin-top: 2%;
}
.flex_col {
  flex-direction: column;
  justify-content: center !important;
}

.arrow .triangle {
  position: absolute;
  background-color: var(--heavenly);
  opacity: 0.6;
  z-index: 0;
}
.rtl .arrow .triangle1 {
  margin-right: 10%;
  margin-top: 2%;
}
.rtl .arrow .triangle2 {
  margin-right: 2%;
}
.ltr .arrow .triangle1 {
  margin-left: 12%;
  margin-right: 10% !important;
}
.ltr .arrow .triangle2 {
  margin-left: 5%;
}
.arrow .triangle:before,
.arrow .triangle:after {
  content: "";
  position: absolute;
  background-color: inherit;
}

.arrow .triangle,
.arrow .triangle:before,
.arrow .triangle:after {
  width: 0.6em;
  height: 0.6em;
  border-top-right-radius: 40%;
  cursor: pointer;
}

.rtl .arrow .triangle {
  transform: rotate(-150deg) skewX(-30deg) scale(1, 0.866);
}
.ltr .arrow .triangle {
  transform: rotate(-90deg) skewX(-30deg) scale(1, 0.866);
}
.ltr .arrow .triangle2 {
  transform: rotate(-30deg) skewX(-30deg) scale(1, 0.866);
}

.arrow .triangle:before {
  transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707)
    translate(0, -50%);
}

.arrow .triangle:after {
  transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414) translate(50%);
}

.rtl .arrow .triangle2 {
  transform: rotate(-90deg) skewX(-30deg) scale(1, 0.866);
}
.arrow .triangle2:before {
  transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707)
    translate(0, -50%);
}

.arrow .triangle2:after {
  transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414) translate(50%);
}
.rtl .arrow .triangle_control {
  position: absolute;
  top: -1px;
  right: 54.2%;
}
.ltr .arrow .triangle_control {
  position: absolute;
  top: -1px;
  left: 50%;
}

.product_img_overlay {
  position: fixed !important;
  inset: 0;
  width: 100%;
  height: 100vh;
  background: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999999 !important;
}

.product_img_overlay .icons {
  position: absolute;
  top: 1rem;
  right: 2rem;
  z-index: 99999 !important;
}

.product_img_overlay .icons .icon svg {
  font-size: 1.5rem;
  cursor: pointer;
  color: #f2f2f2;
}

.product_img_overlay .share_list {
  background-color: #f2f2f2;
  border-radius: 6px;
  padding: 1rem 2rem;
  margin-top: 1rem;
  min-width: 170px;
  flex-direction: column;
}

.product_img_overlay .share_list button {
  background: transparent;
  color: #000;
  cursor: pointer;
  border: none;
  width: fit-content;
  font-size: 0.9rem;
  padding-left: 1rem;
}
@media (min-width: 577px) and (max-width: 992px){
  /* For tablets: */
  .arrow .triangle1 {
    margin-top: 0.3rem !important;
  }
  .product_container .product .product_row .card .img {
    min-width: 10vw !important;
    min-height: 24vh !important;
  }
  .product_card {
    margin-top: 5% !important;
    min-width: 90% !important;
    height: 35vh !important;
    border: none !important;
    border-radius: 9px !important;
    box-shadow: 1px 1px 10px var(--gray);
  }
}


@media (max-width: 768px) {
  /* For tablets: */
  .arrow .triangle1 {
    margin-top: 0.3rem !important;
  }
  .product_container .product .product_row .card .img {
    min-width: 10vw !important;
    min-height: 24vh !important;
  }
  .product_card {
    margin-top: 5% !important;
    min-width: 90% !important;
    height: 35vh !important;
    border: none !important;
    border-radius: 9px !important;
    box-shadow: 1px 1px 10px var(--gray);
  }
}

@media (max-width: 425px) {
  /* For mobile phones: */
}