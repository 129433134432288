* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.App {
  overflow: hidden;
  width: 100%;
  height: 100%;
  font-family: myfont;
}
.App .loading {
  margin-top: 16rem;
  flex-direction: column;
  align-items: center;
  overflow: visible !important;
}
.App .loading .loading_logo {
  width: 10vw;
  margin-bottom: 2%;
}
.App .loading img {
  animation-name: bouncing;
  animation-duration: 1s;
}
@keyframes bouncing {
  0% {
    transform: scale(0.1);
  }
  20% {
    transform: scale(0.8);
  }
  40% {
    transform: scale(1.5);
  }
  60% {
    transform: scale(0.1);
  }
  80% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}
:root {
  --main: #0b057a;
  --white: #f4f5f9;
  --bink: #ff0099;
  --heavenly: #29abe2;
  --gray: #4d4d4d;
  font-size: 16px;
}
@font-face {
  font-family: myFont;
  src: url(../public/Fonts/ArbFONTS-DINNextLTArabic-Regular-4.ttf);
}
@font-face {
  font-family: myFont;
  src: url(../public/Fonts/ArbFONTS-DINNextLTArabic-Bold-2.ttf);
  font-weight: bold;
}
@font-face {
  font-family: myFont;
  src: url(../public/Fonts/ArbFONTS-DINNextLTArabic-Bold-3.ttf);
  font-weight: bolder;
}
@font-face {
  font-family: myFont;
  src: url(../public/Fonts/ArbFONTS-DINNextLTArabic-Heavy2-2.ttf);
  font-weight: 700;
}
@font-face {
  font-family: myFont;
  src: url(../public/Fonts/ArbFONTS-DINNextLTArabic-Medium-3.ttf);
  font-weight: 400;
}
@font-face {
  font-family: myFont;
  src: url(../public/Fonts/ArbFONTS-DINNextLTArabic-Medium-4.ttf);
  font-weight: 500;
}
body {
  font-family: myfont !important;
}
ul {
  list-style: none;
}
img {
  width: 100%;
}
a {
  text-decoration: none !important;
  color: #fff !important;
}
a,
Link {
  cursor: pointer !important;
}
p {
  font-size: 17px;
  font-weight: 500 !important;
}
ul {
  padding-left: 0rem !important;
}
.map_text .triangle_control {
  z-index: 1;
  opacity: 1;
}
.map_text h4,
.map_text h5 {
  position: relative;
  z-index: 111;
}

.contact_container {
  background-color: var(--white);
  margin-bottom: 1% !important;
}
.Contact,
.Product_page {
  overflow: hidden;
}
.homepage_product .product {
  background-color: var(--main) !important;
  border-radius: 0;
}
.homepage_product .product h4,
.homepage_product .product p {
  color: #fff !important;
}
.brandpage_product .product {
  background-color: #fff !important;
  color: var(--main);
}
.page_product .product {
  background-color: var(--white) !important;
  color: var(--main) !important;
}
.page_product .product p,
.brandpage_product .product p {
  color: var(--gray);
}
.page_product,
.product_Brand {
  margin-bottom: 6% !important;
}
.product_Brand {
  margin-top: -7%;
}
.Home {
  overflow: hidden;
}
.triangle_control {
  position: relative;
  left: -80%;
  bottom: 9rem;
  z-index: 10101010;
}
.rtl .triangle_control {
  left: -80%;
}
.ltr .triangle_control {
  left: 82%;
}

.triangle {
  position: absolute;
  background-color: var(--heavenly);
  cursor: pointer;
  transition: 0.5s;
}

.triangle:before,
.triangle:after {
  content: "";
  position: absolute;
  background-color: inherit;
}
.triangle,
.triangle:before,
.triangle:after {
  width: 12em;
  height: 12em;
  border-top-right-radius: 40%;
  z-index: 11;
}

.rtl .triangle {
  transform: rotate(-90deg) skewX(-30deg) scale(1, 0.866);
}
.ltr .triangle {
  transform: rotate(-30deg) skewX(-30deg) scale(1, 0.866);
}
.triangle:before {
  transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707)
    translate(0, -50%);
}
.triangle:after {
  transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414) translate(50%);
}

.foot .triangle {
  animation-name: triangle;
  animation-duration: 5s;
  animation-iteration-count: infinite;


}
@keyframes triangle{
  0%{top:0}
  5%{top:-.3rem}
  10%{top:-.6rem}
  15%{top:-.9rem}
  20%{top:-1.2rem}
  25%{top:-1.5rem}
  30%{top:-1.2rem}
  35%{top:-.9rem}
  40%{top:-.6rem}
  45%{top:-.3rem}
  50%{top: 0rem}
  55%{top:.6rem}
  60%{top:.9rem}
  65%{top:1.2rem}
  70%{top:1.5rem}
  75%{top:1.2rem}
  80%{top:.9rem}
  85%{top:.6rem}
  90%{top:.3rem}
  95%{top:0rem}
  100%{top:0rem}

}
.header_hover {
  background-color: #0b057a7e;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 11;
}
.ma-right .prod_title .triangle_control {
  right: 60%;
}
.ma-right .prod_title.m-right .triangle_control {
  right: 62%;
}
.m-small .prod_title.m-right .triangle_control {
  right: 56% !important;
}
.ma-right .prod_title .triangle,
.ma-right .prod_title .triangle:before,
.ma-right .prod_title .triangle:after {
  width: 2.4em !important;
  height: 2.4em !important;
}
.product {
  box-shadow: 1px 1px 10px #4d4d4d70;
}
.m-right .prod_title .triangle_control {
  right: 56% !important;
}
@media (min-width: 577px) and (max-width: 992px) {
  :root {
    font-size: 14px;
  }

  .App .loading {
    margin-top: 30rem;
  }
  .triangle,
  .triangle:before,
  .triangle:after {
    width: 9em;
    height: 9em;
  }
  .triangle_control {
    bottom: 7rem;
  }
  .App .loading_logo {
    width: 20vw !important;
  }
}
@media (max-width: 768px) {
  :root {
    font-size: 14px;
  }

  .App .loading {
    margin-top: 30.7rem;
  }
  .triangle,
  .triangle:before,
  .triangle:after {
    width: 9em;
    height: 9em;
  }
  .triangle_control {
    bottom: 7rem;
  }
  .App .loading_logo {
    width: 20vw !important;
  }
}

@media (max-width: 425px) {
  :root {
    font-size: 13px;
  }
  .App .loading {
    margin-top: 17rem;
  }
  .App .loading_logo {
    width: 20vw !important;
  }
  .rtl .triangle,
  .rtl .triangle:before,
  .rtl .triangle:after {
    width: 8em;
    height: 8em;
  }
  .ltr .triangle,
  .ltr .triangle:before,
  .ltr .triangle:after {
    width: 7em;
    height: 7em;
  }

  .ltr .triangle_control {
    left: 74%;
    bottom: 6rem;
  }

  .rtl .triangle_control {
    left: -71%;
  }
}
