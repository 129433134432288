.product {
  z-index: 11111;
  top: -7rem !important;
  border-radius: 8px;
  background-color: var(--white);
}
.zahra_page .product {
  position: relative;
  min-height: 100vh;
}
.packaging_height {
  height: 25rem;
}
 .rtl .policy_paragraph .MsoNormal span{
  font-size: 1.1rem !important;
  line-height: 2.5rem !important;
  font-weight: 400 !important;
  color: #635d5d !important;

 }
.prod_title p {
  color: var(--gray);
}
.zahra_page p {
  color: #635d5d !important;
  font-size: 15px !important;
  width: 70%;
  margin-left: 0 !important;
  line-height: 2.2;
}

.rtl .zahra_page p div {
  text-align: right !important;
}
.rtl .zahra_page p {
  text-align: right !important;
  padding: 0 30px;
  margin-right: auto;
  margin-top: -3rem;
}
.ltr .zahra_page p {
  margin-left: auto;
  width: 85%;
  margin-right: 2rem;
  text-align: left !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-left: 0 !important;
  margin-top: -1rem;
}
.ltr
  .Product_page
  .product_container.packaging
  .prod_title
  .map_text
  .triangle_control {
  left: 54% !important;
}

.packaging .triangle-control-com {
  /* margin-right: 5rem; */
  left: -2rem;
  top: 5rem;
}

/* zahra-page-triangle */
.rtl .packaging .packaging .triangle-control-com {
  /* margin-right: 5rem; */
}
.rtl .prod_title.m_right .triangle_control {
  right: 55% !important;
}
.ltr .m_right .triangle_control {
  left: 54% !important;
}
.rtl .m_right .triangle,
.rtl .m_right .triangle:before,
.rtl .m_right .triangle:after {
  width: 2.4em !important;
  height: 2.4em !important;
}
.ltr .m_right .triangle,
.ltr .m_right .triangle:before,
.ltr .m_right .triangle:after {
  width: 2em !important;
  height: 2em !important;
}
.rtl .Product_page .margin_right .triangle_control {
  right: 59.4% !important;
}
.ltr .Product_page .margin_right .triangle_control {
  left: 54% !important;
}

.product_container .policy_paragraph{
/* text-align: center; */
  font-size: 1.2rem;
  font-weight: 400;
  color: var(--gray);
}
.rtl .product_container .policy_paragraph,
.rtl .product_container .policy_paragraph p {
  text-align: right !important; 
  color: #635d5d !important;
  font-size: 1.1rem;

}
.ltr .product_container .policy_paragraph {
  margin-left: 0 !important;
  margin-right: 0 !important;
  width: 100% !important;
  text-align: left !important;
  font-size: 1.1rem;

}
.m-mall-screen span{
  font-size: 1.1rem;
}
/* .rtl .packaging:hover .triangle-control-com :last-child {
  animation-name: animation;
  animation-duration: 5s;
  transition: all 1.6s;
}
.ltr .packaging:hover .triangle-control-com :last-child {
  animation-name: animation_ltr;
  animation-duration: 5s;
  transition: all 1.6s;
} */
/*  */
/* .rtl .packaging .triangle-control-com:hover :first-child, */
/* .ltr .packaging .triangle-control-com:hover :first-child { */
/* transform: scale(1.05) translate(-10px, -5px) !important; */
/* box-shadow: rgba(255, 255, 255, 0.55) !important; */
/* } */
/*  */
/* .rtl .packaging .triangle-control-com:hover :nth-child(2), */
/* .ltr .packaging .triangle-control-com:hover :nth-child(2) { */
/* transform: scale(1.03) !important; */
/* box-shadow: rgba(255, 255, 255, 0.55); */
/* } */
.rtl .packaging .triangle-control-com {
  animation-name: triangleleft;
  animation-duration: 5s;
  animation-iteration-count: infinite;
}
.ltr .packaging .triangle-control-com {
  left: calc(100% - 31rem);
  top: -1rem;
  /* transform: rotate(30deg); */
}
.ltr .packaging .triangle-control-com :first-child {
  left: 38rem;
}
.ltr .packaging .triangle-control-com :last-child {
  left: 35rem;
}
/* @keyframes animation { */
  /* 0% { */
    /* left: 30rem; */
  /* } */
  /* 20% { */
    /* left: 28rem; */
  /* } */
  /* 40% { */
    /* left: 26rem; */
  /* } */
  /* 50% { */
    /* left: 24rem; */
  /* } */
  /* 50% { */
    /* left: 24rem; */
  /* } */
  /* 60% { */
    /* left: 26rem; */
  /* } */
  /* 80% { */
    /* left: 28rem; */
  /* } */
  /* 100% { */
    /* left: 30rem; */
  /* } */
/* } */
/* @keyframes animation_ltr { */
  /* 0% { */
    /* right: 50rem; */
  /* } */
  /* 20% { */
    /* right: 48rem; */
  /* } */
  /* 40% { */
    /* right: 46rem; */
  /* } */
  /* 50% { */
    /* right: 42rem; */
  /* } */
  /* 50% { */
    /* right: 42rem; */
  /* } */
  /* 60% { */
    /* right: 46rem; */
  /* } */
  /* 80% { */
    /* right: 48rem; */
  /* } */
  /* 100% { */
    /* right: 50rem; */
  /* } */
/* } */
@media (min-width: 577px) and (max-width: 992px) {
  .rtl .packaging .triangle-control-com :first-child {
    right: -9rem !important;
    top: -16rem !important;
  }
  .ltr .packaging .triangle-control-com :first-child {
    left: 20rem;
  }

  .rtl .packaging .triangle-control-com :last-child {
    right: -6.5rem;
    top: -16rem !important;
  }

  /* For tablets: */
  .packaging .triangle-control-com .triangle-com {
    width: 26rem !important;
    height: 28rem !important;
  }
  .ltr .packaging .triangle-control-com {
    top: -2rem;
    left: 17rem;
  }
  .ltr .packaging .triangle-control-com :last-child {
    left: 0.7rem;
  }
  .ltr .packaging .triangle-control-com :first-child {
    left: 20rem;
  }
  .ltr
    .Product_page
    .product_container.packaging
    .prod_title
    .map_text
    .triangle_control {
    left: 57% !important;
  }
  .rtl .packaging .triangle-control-com {
    margin-top: 9rem;
    margin-right: -6rem;
  }
  .ltr .zahra_page p {
    width: 90%;
    margin-top: 0% !important;
    padding-left: 1rem !important ;
  }
  .ltr .Product_page .margin_right .triangle_control {
    left: 56% !important;
  }
  .product {
    overflow: hidden;
    height: auto;
  }
  .margin_right .triangle_control {
    right: 62.4% !important;
  }

  .zahra_page p {
    width: 80%;
  }
  .zahra_page .product {
    position: relative;
    min-height: auto;
  }
  .product_container .m_right .triangle_control {
    right: 57% !important;
  }
  .Product_page .packaging .margin_right .triangle_control {
    left: 57% !important;
  }
  .rtl .Product_page .packaging .margin_right .triangle_control {
    right: 63% !important;
  }
  .ltr .packaging .triangle-control-com :last-child {
    left: 17rem;
  }
}
@media (max-width: 768px) {
  .rtl .packaging .triangle-control-com :first-child {
    right: -9rem !important;
    top: -16rem !important;
  }
  .ltr .packaging .triangle-control-com :first-child {
    left: 20rem;
  }

  .rtl .packaging .triangle-control-com :last-child {
    right: -6.5rem;
    top: -16rem !important;
  }

  /* For tablets: */
  .packaging .triangle-control-com .triangle-com {
    width: 26rem !important;
    height: 28rem !important;
  }
  .ltr .packaging .triangle-control-com {
    top: -2rem;
    left: 17rem;
  }
  .ltr .packaging .triangle-control-com :last-child {
    left: 0.7rem;
  }
  .ltr .packaging .triangle-control-com :first-child {
    left: 20rem;
  }
  .ltr
    .Product_page
    .product_container.packaging
    .prod_title
    .map_text
    .triangle_control {
    left: 57% !important;
  }
  .rtl .packaging .triangle-control-com {
    margin-top: 9rem;
    margin-right: -6rem;
  }
  .ltr .zahra_page p {
    width: 90%;
    margin-top: 0% !important;
    padding-left: 1rem !important ;
  }
  .ltr .Product_page .margin_right .triangle_control {
    left: 56% !important;
  }
  .product {
    overflow: hidden;
    height: auto;
  }
  .margin_right .triangle_control {
    right: 62.4% !important;
  }

  .zahra_page p {
    width: 80%;
  }
  .zahra_page .product {
    position: relative;
    min-height: auto;
  }
  .product_container .m_right .triangle_control {
    right: 57% !important;
  }
  .Product_page .packaging .margin_right .triangle_control {
    left: 57% !important;
  }
  .rtl .Product_page .packaging .margin_right .triangle_control {
    right: 63% !important;
  }
  .ltr .packaging .triangle-control-com :last-child {
    left: 17rem;
  }
}

@media (max-width: 425px) {
  /* For mobile phones: */
  .ltr .packaging .triangle-control-com :last-child {
    left: 0.7rem;
  }
  .packaging .triangle-control-com .triangle-com {
    width: 22rem !important;
    height: 24rem !important;
  }
  .ltr .packaging .triangle-control-com :first-child {
    left: 3rem;
  }

  .ltr .packaging .triangle-control-com {
    top: -5rem;
    left: 2rem;
  }
  .rtl .packaging .triangle-control-com {
    /* top: -1rem !important; */
    right: 8rem !important;
  }
  .rtl .packaging .triangle-control-com {
    /* top: 11rem !important; */
    right: 17rem !important;
  }
  .rtl .packaging .triangle-control-com :last-child {
    right: -6.5rem !important;
  }
  .ltr .packaging .triangle-control-com :first-child {
    left: 2.5rem;
  }
  .ltr
    .Product_page
    .product_container.packaging
    .prod_title
    .map_text
    .triangle_control {
    left: 61% !important;
  }
  .rtl .packaging .triangle-control-com,
  .ltr .packaging .triangle-control-com {
    margin-top: 5rem;
    margin-right: -4rem;
  }
  .rtl .zahra_page p {
    margin-top: 10% !important;
  }
  .ltr .zahra_page p {
    width: 90%;
    margin-top: 0% !important;
    padding-left: 1.2rem !important ;
  }
  .ltr .Product_page .margin_right .triangle_control {
    left: 59% !important;
  }
  .rtl .Product_page .packaging .margin_right .triangle_control {
    right: 71.5% !important;
  }
  .ltr .product_container .m_right .triangle_control {
    left: 59% !important;
  }
  .margin_right .triangle_control {
    right: 70.4% !important;
  }
  .rtl .zahra-page-triangle.triangle_control {
    top: 7rem !important;
    right: 8rem !important;
  }
  .ltr .zahra-page-triangle.triangle_control {
    top: 3rem !important;
    left: 16rem !important;
  }
  .zahra_page p {
    width: 100% !important;
    color: #635d5d;
    text-align: right;
    padding: 0 15px;
    line-height: 2.2;
    margin-bottom: 5%;
    padding-top: -10% !important;
  }
  .zahra_page .product {
    position: relative;
    min-height: auto;
  }
  .m-mall-screen {
    margin: 0 3% !important;
  }
  .rtl .packaging .triangle-control-com {
    animation-name: packingright !important;
  }
  .ltr .packaging .triangle-control-com {
    animation-name: packing !important;
  }
  @keyframes packing{
    0%{top:-5rem}
    5%{top:-5.3rem}
    10%{top:-5.6rem}
    15%{top:-5.9rem}
    20%{top:-6.2rem}
    25%{top:-6.5rem}
    30%{top:-6.2rem}
    35%{top:-5.9rem}
    40%{top:-5.6rem}
    45%{top:-5.3rem}
    50%{top: -5rem}
    55%{top:-5.3rem}
    60%{top:-5.6rem}
    65%{top:-5.9rem}
    70%{top:-6.2rem}
    75%{top:-6.5rem}
    80%{top:-6.2rem}
    85%{top:-5.9rem}
    90%{top:-5.6rem}
    95%{top:-5.3rem}
    100%{top:-5rem}
  
  }
  @keyframes packingright{
    0%{top:11rem}
    5%{top:10.7rem}
    10%{top:10.4rem}
    15%{top:10.1rem}
    20%{top:9.8rem}
    25%{top:9.5rem}
    30%{top:9.8rem}
    35%{top:10.1rem}
    40%{top:10.4rem}
    45%{top:10.7rem}
    50%{top: 10.7rem}
    55%{top:10.4rem}
    65%{top:10.1rem}
    70%{top:9.8rem}
    75%{top:9.5rem}
    80%{top:9.8rem}
    85%{top:10.1rem}
    90%{top:10.4rem}
    95%{top:10.7rem}
    100%{top:11rem}
  
  }
}
@media (max-width: 380px) {
  .rtl .packaging .triangle-control-com {
    right: 15rem !important;
  }
  .rtl .packaging .triangle-control-com :last-child {
    right: -7rem !important;
  }
  .ltr .packaging .triangle-control-com {
    left: 0.5rem;
  }
}
