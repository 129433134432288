.product {
  z-index: 11111;
  top: -7rem !important;
  border-radius: 8px;
  background-color: var(--white);
}
.about .product {
  position: relative;
  min-height: 100vh;
}

.prod_title p {
  color: var(--gray);
}
.policy_paragraph {
  color: #4d4d4d !important;
  line-height: 2.3;
  font-size: 16px;
  font-weight: 300;
  font-family: myfont;
}
.rtl .description2 {
  margin-right: -10% !important;
  margin-top: 6% !important;
}
.description2 span {
  font-weight: bold;
}
.rtl .list p {
  text-align: right !important;
}
.ltr .list p {
  text-align: left !important ;
}
.about p {
  text-align: right;
  color: #635d5d;
  line-height: 2.2;
  font-size: 16px;
  opacity: 0.9;
}
.about_text .paragraph {
  color: var(--gray);
  font-size: 15px;
}
.rtl .about_text .paragraph {
  text-align: right;
}
.ltr .about_text .paragraph {
  text-align: left;
}
.about .paragraph-header {
  font-size: 17px !important;
  opacity: 1 !important;
}

/* about-page-triangle */
.about-page-triangle.triangle_control .triangle {
  background-color: var(--heavenly);
  opacity: 1;
}

.rtl .about-page-triangle.triangle_control {
  top: 10rem !important;
  right: 8rem !important;
}
.ltr .about-page-triangle.triangle_control {
  left: 8rem !important;
}
.description2 .list,
.description2 .list p {
  color: #635d5d;
  font-size: 15px !important;
}

.ltr .triangle-control-com {
  left: 0 !important;
}

.triangle-control-com {
  transition: 0.5s;
 } 
/*
/* .rtl .about .triangle-control-com:hover :first-child { */
  /* transform: scale(1.1) translate(-10px, -5px) !important; */
  /* box-shadow: rgba(255, 255, 255, 0.55) !important; */
/* } */
/*  */
/* .rtl .about .triangle-control-com:hover :nth-child(2) { */
  /* transform: scale(1.05) !important; */
  /* box-shadow: rgba(255, 255, 255, 0.55); */
/* } */
/* .ltr .triangle-control-com:hover { */
  /* transform: scale(1.13) !important; */
  /* transition: all .5s; */
  /* box-shadow: rgba(255, 255, 255, 0.55) !important; */
/* } */
.triangle-control-com {
  animation-name: triangle;
  animation-duration: 5s;
  animation-iteration-count: infinite;

}

.triangle-control-com {
  position: relative;
  left: 2rem;
}

.triangle-control-com :first-child {
  right: -2rem !important;
}
.rtl .right .description2 {
  text-align: right;
}
.lt .About .aboutright .prod_title .map_text .triangle_control {
  left: 56% !important;
}
.rtl .triangle-control-com :nth-child(1) {
  top: -7rem !important;
}
.rtl .triangle-control-com :nth-child(2) {
  top: -7rem !important;
}


.rtl .description2 {
  margin-right: -15%;
}
.ltr .description2 {
  margin-left: -20%;
  text-align: left;
}
.ltr .policy_paragraph {
  margin-left: 95% !important;
  width: 40%;
}
.description2 .span_head {
  font-weight: bold;
  color: var(--gray);
}
.about p {
  text-align: right;
  color: #635d5d;
  line-height: 2.2;
  font-size: 16px;
  opacity: 0.9;
}
.about .paragraph-header {
  font-size: 17px !important;
  opacity: 1 !important;
}

.ltr .about_text {
  margin-left: 13%;
  margin-right: 0 !important;
}
.ltr .paragraph {
  margin-right: 2% !important;
}
/* .ltr .right .triangle-control-com:hover :first-child { */
  /* transform: rotateY(0deg) scale(1.1) translate(10px, 5px) !important; */
  /* box-shadow: rgba(255, 255, 255, 0.55) !important; */
/* } */
/*  */
/* .ltr .right .triangle-control-com:hover :nth-child(2) { */
  /* transform: rotateY(0deg) scale(1.05) !important; */
  /* box-shadow: rgba(255, 255, 255, 0.55); */
/* }  */
@media (min-width: 577px) and (max-width: 992px) {
  /* For tablets: */
  .about_text {
    text-align: center;
  }

  .about-page-triangle.triangle_control {
    top: 10.5rem !important;
    right: 20rem !important;
  }
  .about-page-triangle.triangle_control .triangle,
  .about-page-triangle.triangle_control .triangle::before,
  .about-page-triangle.triangle_control .triangle::after {
    width: 13em;
    height: 13em;
    border: 2px 0px 1px 0;
  }

  .about-page-triangle-bg.about-page-triangle.triangle_control {
    right: 21.5rem !important;
    top: 12rem !important;
  }

  .about_text {
    margin-top: 52%;
  }

  .triangle-control-com {
    top: -8rem;
    right: 2rem;
  }

  .ltr .triangle-control-com {
    top: -1rem;
  }
  .ltr .triangle-control-com :first-child {
    left: 10rem;
  }
  .ltr .triangle-control-com :last-child {
    left: 11.7rem;
  }
  .rtl .triangle-control-com :nth-child(1) {
    top: -10rem !important;
  }
  .rtl .triangle-control-com :nth-child(2) {
    top: -10rem !important;
  }
  .rtl .m-right .triangle-control-com-left :first-child {
    top: 7rem !important;
}
.rtl .m-right .triangle-control-com-left :last-child {
  top: 7rem !important;
}
  .rtl .description2 {
    margin-right: -5%;
  }
  .rtl .m-right .triangle-control-com-left :first-child {
    top: 3rem;
  }
  .rtl .m-right .triangle-control-com-left :last-child {
    top: 3rem;
  }
  .ltr .about_text {
    margin-left: 0%;
    margin-right: 0 !important;
  }
  .ltr .about_text .paragraph {
    text-align: left;
    padding-left: 0% !important;

  }
  .rtl .triangle-control-com-left :first-child {
    left: 0rem !important;
    top: 4rem;
}
.rtl .triangle-control-com-left :last-child {
  top: 4rem;
}
  .ltr .About .about-us .left .prod_title .map_text .triangle_control {
    left: 27rem !important;
}
.ltr .About .about-us .left.modals .prod_title .map_text .triangle_control {
  left: 33rem !important;
}
.ltr .medals .About .about-us .left .prod_title.m-right .map_text .triangle_control {
  left: 30rem !important;
}
  .ltr .About .ma-right .right .prod_title .map_text .triangle_control{
        left: 34rem !important;
}
.ltr .About .aboutleft .left .prod_title .map_text .triangle_control {
  left: 41rem !important;
}
  .ltr .description2 {
    margin-left: 0%;
  }
  .ltr .triangle-control-com {
    position: relative;
    left: 6rem;
  }
  .ltr .left .triangle-control-com-left {
    position: absolute;
    right: 30rem !important;
  }
  .rtl .triangle-control-com {
    right: 15rem !important;
    top: 0.5rem;
  }
  .about_text {
    text-align: center;
  }
  .about_text {
    margin-top: 52%;
  }
}

@media (max-width: 768px) {
  /* For tablets: */
  .about_text {
    text-align: center;
  }

  .about-page-triangle.triangle_control {
    top: 10.5rem !important;
    right: 20rem !important;
  }
  .about-page-triangle.triangle_control .triangle,
  .about-page-triangle.triangle_control .triangle::before,
  .about-page-triangle.triangle_control .triangle::after {
    width: 13em;
    height: 13em;
    border: 2px 0px 1px 0;
  }

  .about-page-triangle-bg.about-page-triangle.triangle_control {
    right: 21.5rem !important;
    top: 12rem !important;
  }

  .about_text {
    margin-top: 52%;
  }

  .triangle-control-com {
    top: -8rem;
    right: 2rem;
  }

  .ltr .triangle-control-com {
    top: 0;
  }
  .ltr .triangle-control-com :first-child {
    left: 10rem;
  }
  .ltr .triangle-control-com :last-child {
    left: 11.7rem;
  }
  .rtl .triangle-control-com :nth-child(1) {
    top: -9rem !important;
  }
  .rtl .triangle-control-com :nth-child(2) {
    top: -9rem !important;
  }
  .rtl .description2 {
    margin-right: -5%;
  }
  .rtl .m-right .triangle-control-com-left :first-child {
    top: 3rem;
  }
  .rtl .m-right .triangle-control-com-left :last-child {
    top: 3rem;
  }
  .ltr .about_text {
    margin-left: 0%;
    margin-right: 0 !important;
  }
  .ltr .about_text .paragraph {
    text-align: left;
    padding-left: 5% !important;
  }
  .ltr .description2 {
    margin-left: -5%;
  }
  .ltr .triangle-control-com {
    position: relative;
    left: 6rem;
  }
  .ltr .left .triangle-control-com-left {
    position: absolute;
    right: 30rem !important;
  }
  .rtl .triangle-control-com {
    right: 15rem !important;
    top: 0.5rem;
  }
  .about_text {
    text-align: center;
  }
  .about_text {
    margin-top: 52%;
  }
}
@media (max-width: 576px) {
  /* Styles for small devices */
  .about_text {
    text-align: center;
  }
  .about_text {
    margin-top: 79%;
  }
  .rtl .right .description2{
    margin-right: 2% !important;
  }
  .ltr .right .description2{
    margin-left: 2% !important;
  }
  .rtl .m-right .triangle_control {
    right: 63% !important;
}
.rtl .m-right.prod_title .triangle_control {
  right: 56% !important;
}
.rtl .ma-right .prod_title.m-right .triangle_control {
  right: 73% !important;
}
  .triangle-control-com {
    top: -7rem;
    right: 3rem;
  }
  .rtl .triangle-control-com :nth-child(1) {
    right: -11rem !important;
  }
  .rtl .triangle-control-com :nth-child(2) {
    right: -9rem !important;
  }
  .rtl .triangle-control-com-left :nth-child(1) {
    left: 5rem !important;
    top: 2rem !important;
  }
  .rtl .Home .brand .map_text .triangle_control,
  .rtl .Product_page .mp-right .map_text .triangle_control {
    right: 59% !important;
}
  .rtl .triangle-control-com-left :nth-child(2) {
    left: 7rem !important;
    top: 2rem !important;
  }
  .rtl .medals .triangle-control-com-left :nth-child(1) {
    top: -2rem !important;
  }
  .rtl .medals .triangle-control-com-left :nth-child(2) {
    top: -2rem !important;
  }
  .rtl .aboutleft.m-right .triangle-control-com-left :nth-child(1) {
    top: 6rem !important;
  }
  .rtl .aboutleft.m-right .triangle-control-com-left :nth-child(2) {
    top: 6rem !important;
  }
  .rtl .aboutleft .triangle-control-com-left :nth-child(1) {
    left: 5rem !important;
    top: 6rem !important;
  }
  .rtl .aboutleft .triangle-control-com-left :nth-child(2) {
    left: 7rem !important;
    top: 6rem !important;
  }
  /* For mobile phones: */
  .about_text {
    text-align: center;
  }
  .ltr .triangle-control-com :first-child {
    left: 3rem;
  }
  .ltr .triangle-control-com :last-child {
    left: 4.9rem;
  }

  .about-page-triangle.triangle_control {
    top: 10.5rem !important;
    right: 11rem !important;
  }
  .about-page-triangle.triangle_control .triangle,
  .about-page-triangle.triangle_control .triangle::before,
  .about-page-triangle.triangle_control .triangle::after {
    width: 13em;
    height: 13em;
    border: 2px 0px 1px 0;
  }

  .about-page-triangle-bg.about-page-triangle.triangle_control {
    right: 12.4rem !important;
    top: 12.5rem !important;
  }

  .about_text {
    margin-top: 79%;
  }
}
@media (max-width: 425px) {
  .rtl .About .ma-right.aboutleft .right .prod_title .map_text .triangle_control {
    right: 63.5% !important;
}
.ltr .About .ma-right.aboutleft .right .prod_title .map_text .triangle_control {
  left: 99% !important;
}
  .about_text {
    margin-top: 79%;
  }
  .rtl .right .description2{
    margin-right: 2% !important;
  }
  .ltr .right .description2{
    margin-left: 2% !important;
  }
  .triangle-control-com {
    top: -7rem;
    right: 3rem;
  }
  .rtl .triangle-control-com :nth-child(1) {
    right: -11rem !important;
  }
  .rtl .triangle-control-com :nth-child(2) {
    right: -9rem !important;
  }
  .rtl .triangle-control-com-left :nth-child(1) {
    left: 5rem !important;
    top: -1rem !important;
  }
  .rtl .triangle-control-com-left :nth-child(2) {
    left: 7rem !important;
    top: -1rem !important;
  }
  .rtl .aboutleft .triangle-control-com-left :nth-child(1) {
    left: 5rem !important;
    top: 6rem !important;
  }
  .rtl .aboutleft .triangle-control-com-left :nth-child(2) {
    left: 7rem !important;
    top: 6rem !important;
  }
  /* For mobile phones: */
  .about_text {
    text-align: center;
  }
  .ltr .triangle-control-com :first-child {
    left: 3rem;
  }
  .ltr .triangle-control-com :last-child {
    left: 4.9rem;
  }

  .about-page-triangle.triangle_control {
    top: 10.5rem !important;
    right: 11rem !important;
  }
  .about-page-triangle.triangle_control .triangle,
  .about-page-triangle.triangle_control .triangle::before,
  .about-page-triangle.triangle_control .triangle::after {
    width: 13em;
    height: 13em;
    border: 2px 0px 1px 0;
  }

  .about-page-triangle-bg.about-page-triangle.triangle_control {
    right: 12.4rem !important;
    top: 12.5rem !important;
  }

  .about_text {
    margin-top: 79%;
  }
}
@media (max-width:380px) {
  .rtl .medals .triangle-control-com-left :nth-child(1) {
    top: -3rem !important;
}
.rtl .medals .triangle-control-com-left :nth-child(2) {
  top: -3rem !important;
}
.ltr .About .ma-right.aboutleft .right .prod_title .map_text .triangle_control {
  left: 105% !important;
}
}
