.contact-page {
  position: relative;
  z-index: 11111;
  top: -9rem;
  border-radius: 8px;
  background-color: #fff !important;
  color: var(--gray);
  box-shadow: 1px 1px 10px #4d4d4d8c;
}

.contact_title p {
  margin-top: 2%;
  color: var(--gray);
  font-size: 15px;
  font-weight: 300;
}
.rtl .contact-page .contact_title .map_text .triangle_control{
  top:-1% !important;
  right: 53% !important;
}
.ltr .contact-page .contact_title .map_text .triangle_control{
  left: 52% !important;
}

@media (max-width: 768px) {
  /* For tablets: */
  .contact-page {
    top: -4rem;
  }.rtl .contact-page .contact_title .map_text .triangle_control{
    right: 54% !important;
  }
  .ltr .contact-page .contact_title .map_text .triangle_control {
    left: 53% !important;
}
}
  @media (max-width: 425px) {
    /* For mobile phones: */
  .rtl .contact-page .contact_title .map_text .triangle_control{
    right: 57% !important;
  }
  .ltr .contact-page .contact_title .map_text .triangle_control{
    left: 54% !important;
  }
  .contact_title p {
    margin-top: 3%;
  }
  }
