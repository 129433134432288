.slider_card {
  max-width: 17vw;
  border: none !important;
  border-radius: 9px !important;
  box-shadow: 1px 1px 10px var(--gray);
  min-height: 50vh;
  max-height: 50vh;
  cursor: pointer;
}
.card:hover {
  transform: scale(1.1);
  transition: all 0.7s;
}
.card .img {
  max-width: 70%;
  max-height: 50% !important;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.slider_card .x_icon {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 1.2rem;
  z-index: 99;
}

.slider_card .x_icon:hover svg {
  color: rgb(139, 18, 18);
}

.card.hovered_card {
  /* width: 20vw !important; */
  transform: scale(1.29, 1.1) !important;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
/* .card.card.hovered_card .slider_card_overlay {
  width: 100%;
  height: 100%;
  inset: 0;
  z-index: -1;
} */

.card .img h6 {
  color: var(--main);
  text-align: center;
  margin-bottom: 7%;
  font-size: 1rem;
  background-color: #fff !important;
}
.card .img {
  max-height: 70%;
  overflow: hidden;
}
.card .product_btn {
  background-color: var(--heavenly);
  border: none;
  width: 50%;
  min-height: 10%;
  max-height: 12%;
  padding-bottom: 2%;
  margin-left: 50%;
  border-radius: 15px 0 9px 0;
  color: var(--main);
  font-size: 1rem;
  font-weight: 300 !important;
  overflow: hidden;
  transition: 0.5s ease-in;
  position: relative;
}

.card.card.hovered_card .product_btn {
  position: absolute;
  bottom: 0;
  right: 0;
}

.card .img h6 {
  position: absolute;
  top: 0;
  /* left: 50%;
  transform: translateX(-50%); */
  width: fit-content;
  margin-bottom: 1rem;
}

.card .product_btn::after {
  content: "»";
  position: absolute;
  opacity: 0;
  top: 3px;
  right: -20px;
  transition: 0.5s;
}

.card .product_btn:hover {
  padding-right: 24px;
  padding-left: 8px;
}

.card .product_btn:hover::after {
  opacity: 1;
  right: 10px;
}

.ltr .slider_card h6 {
  text-align: center !important;
  margin-left: 0 !important;
}
@media (min-width: 577px) and (max-width: 992px) {
  .slider_card.card {
    max-width: 60vw !important;
    border: none !important;
    border-radius: 9px !important;
    box-shadow: 1px 1px 10px var(--gray);
    margin: 0 4em 0 0;
    max-height: 20vh !important;
    min-height: 20vh !important;
  }
}

@media (max-width: 768px) {
  .slider_card.card {
    max-width: 60vw !important;
    border: none !important;
    border-radius: 9px !important;
    box-shadow: 1px 1px 10px var(--gray);
    margin: 0 4em 0 0;
    max-height: 30vh !important;
    min-height: 30vh !important;
  }
}
@media (max-width: 425px) {
  .slider_card.card {
    max-width: 80vw !important;
    border: none !important;
    border-radius: 9px !important;
    box-shadow: 1px 1px 10px var(--gray);
    margin: 0 4em 0 0;
    height: 33vh !important;
  }
  .slider_card.card .img h6 {
    font-size: 1.1rem !important;
  }
}
