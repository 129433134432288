.about h5 {
  text-align: center;
  color: var(--main) !important;
  font-size: 1.4rem;
  font-weight: 800;
  margin-bottom: 3%;
}
.about .map_text span {
  font-weight: bold;
  color: var(--gray);
}
.rtl .about .map_text p {
  text-align: right;
}
.ltr .about .map_text p {
  text-align: left;
}
.about .about_prag {
  color: #635d5d !important;
}
.about .btnn {
  text-align: center;
  background-color: var(--heavenly);
  color: var(--main) !important;
  border-radius: 50px 50px;
  padding: 7px 25px;
  word-spacing: 2px;
  border: none;
}
.about .btnn:hover {
  background-color: #fff;
  border: 2px solid var(--heavenly);
  color: var(--heavenly) !important;
  transition: all 0.5s;
}

.about .triangle {
  position: absolute;
  background-color: var(--heavenly);
  opacity: 0.6;
  z-index: 0;
}
.about .triangle:before,
.about .triangle:after {
  content: "";
  position: absolute;
  background-color: inherit;
}
.about .triangle,
.about .triangle:before,
.about .triangle:after {
  width: 2.1em;
  height: 2.1em;
  border-top-right-radius: 40%;
}

.rtl .about .triangle {
  transform: rotate(-150deg) skewX(-30deg) scale(1, 0.866);
}
.ltr .about .triangle {
  transform: rotate(-90deg) skewX(-30deg) scale(1, 0.866);
}
.about .triangle:before {
  transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707)
    translate(0, -50%);
}
.about .triangle:after {
  transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414) translate(50%);
}

.about .triangle_control {
  position: absolute;
}
.rtl .about .map_text.about_title .triangle_control {
  right: 52.5% ;
  top: -1px;
}
.ltr .Home .about .map_text .triangle_control {
  left: 51% !important;
  top: -6px;
}
@media (max-width: 425px) {
  .about .map_text p.about_prag {
    margin-bottom: 5% !important;
  }
  .rtl .about .triangle_control {
    right: 52.5% !important;
    top: -1px;
  }
  .about .btnn {
    font-size: 1.2rem !important;
  }
}
