.brand {
  overflow: hidden;
  top: -10px;
}
.brand .brand_slider .brand_card {
  padding-top: 2px;
  box-shadow: 5px 5px 10px #4d4d4db0;
  height: 25vh;
  width: 17vw !important;
  padding: 0 1% !important;
  border-radius: 9px;
  overflow: hidden !important;
}
.brand .brand_slider .brand_card img{
  border-radius: 9px;

}
.brand .brand_slider .slick-dots {
  display: none !important;
}
.brand .brand_img {
  width: 100% !important;
  padding: 9px;
  border-radius: 2px;
}
.brand .col_card {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.rtl .Home .mp-right .map_text .triangle_control,
.rtl .Product_page .mp-right .map_text .triangle_control {
  right: 54%;
  top: -4px;
}
.ltr .mp-right .map_text .triangle_control {
  left: 51% !important;
}
img {
  width: 100%;
  height: 100%;
}
@media (min-width: 577px) and (max-width: 992px) {
  /* For mobile phones: */
  .brand {
    margin-bottom: 20%;
  }

  .brand .brand_card {
    margin-bottom: 9% !important;
  }
  .brand .brand_slider .brand_card {
    height: 15vh;
    width: 20vw !important;
  }
  .rtl .Home .brand .map_text .triangle_control,
  .rtl .Product_page .mp-right .map_text .triangle_control {
    right: 56% !important;
  }
}

@media (max-width: 768px) {
  /* For mobile phones: */
  .brand {
    margin-bottom: 20%;
  }
  .brand .brand_card {
    margin-bottom: 9% !important;
  }
  .brand .brand_slider .brand_card {
    height: 15vh;
    width: 20vw !important;
  }
  .rtl .Home .brand .map_text .triangle_control,
  .rtl .Product_page .mp-right .map_text .triangle_control {
    right: 56% !important;
  }
}

@media (max-width: 576px){
  .rtl .Home .brand .map_text .triangle_control, .rtl .Product_page .mp-right .map_text .triangle_control {
    right: 62% !important;
}
}
@media (max-width: 425px) {
  /* For tablets: */

  .brand .brand_slider .brand_card {
    height: 10vh;
    width: 20vw !important;
  }
  .rtl .Home .brand .map_text .triangle_control,
  .rtl .Product_page .mp-right .map_text .triangle_control {
    right: 61% !important;
  }
}
