.About .head_img {
    background-position: 10% 85%;
}
.about-us{
    padding-bottom: 10em !important;
}
.aboutleft.m-right .map_text .triangle{
    right: 4.5em !important;
}

@media (min-width: 577px) and (max-width: 992px) {
    /* For mobile Tablet: */
    .aboutleft.m-right .map_text .triangle{
        right: 6em !important;
    }
    
    .medals.m-right .map_text .triangle{
        right: 1.6em !important;

    }
}

@media (max-width: 768px) {
    /* For mobile Tablet: */
    .aboutleft.m-right .map_text .triangle{
        right: 6em !important;
    }
    
    .medals.m-right .map_text .triangle{
        right: 1.6em !important;

    }
}
@media (max-width: 425px) {
    /* For mobile phones: */
    .medals.m-right .map_text .triangle{
        right: 2.5em !important;

    }
    .aboutleft.m-right .map_text .triangle{
        right: 7em !important;
    }
    .aboutleft.ma-right .map_text .triangle{
        right: 3em !important;

    }
}