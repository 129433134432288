
.product h5{
    font-family: myfont;
    text-align: center;
    font-size: 1.4rem;
    font-weight: 800;
    margin-bottom: 3%;
    z-index: 11;

}
/* .product p{ */
    /* text-align: center; */
    /* font-size: 1.2rem; */
    /* font-weight: 400; */
    /* color: var(--white); */
/* } */
.product .triangle {
	position: absolute;
	background-color: var(--heavenly);
    opacity: .6;
    z-index: 0;
}
.product .triangle:before,
.product .triangle:after {
	content: '';
	position: absolute;
	background-color: inherit;
}
.product .triangle,
.product .triangle:before,
.product .triangle:after {
	width: 1.8em;
	height: 1.8em;
	border-top-right-radius: 40%;
}

.rtl .product .triangle {
	transform: rotate(-150deg) skewX(-30deg) scale(1,.866);
}
.ltr .product .triangle {
	transform: rotate(-90deg) skewX(-30deg) scale(1,.866);
}
.product .triangle:before {
	transform: rotate(-135deg) skewX(-45deg) scale(1.414,.707) translate(0,-50%);
}
.product .triangle:after {
	transform: rotate(135deg) skewY(-45deg) scale(.707,1.414) translate(50%);
}

.product .triangle_control{
    position: absolute;
    top:-1px;
}
.ltr .product .triangle_control{
    top:-3px;
    left: 51%;
}
.rtl .product .triangle_control{
    top:-1px;
    right: 53%;
}
.ltr .slider .slick-prev {
    margin-left: auto;
  }
  .ltr .homepage_product .product .prod_title .map_text .triangle_control{
    left: 51% !important;
  }
  .ltr .Product_page .brandpage_product .product .prod_title .map_text .triangle_control{
    left: 56% !important;
  }
  .ltr .About .brandpage_product .product .prod_title .map_text .triangle_control{
    left: 56% !important;
  }
  .ltr .details .page_product .prod_title .map_text .triangle_control{
    left:56% !important;
  }
  .rtl .details .page_product .prod_title .map_text .triangle_control{
    right:58% !important;
  }
  @media (min-width: 577px) and (max-width: 992px) {
    .ltr .Product_page .brandpage_product .product .prod_title .map_text .triangle_control{
        left: 62% !important;
      }
      .ltr .details .page_product .prod_title .map_text .triangle_control{
        left:58% !important;
      }
  }
  @media (max-width: 768px) {
    .ltr .Product_page .brandpage_product .product .prod_title .map_text .triangle_control{
        left: 62% !important;
      }
      .ltr .details .page_product .prod_title .map_text .triangle_control{
        left:58% !important;
      }
  }
  @media (max-width: 576px) {
    .ltr .Product_page .brandpage_product .product .prod_title .map_text .triangle_control {
      left: 72% !important;
  }
  }
  @media (max-width: 425px) {
    .ltr .Product_page .brandpage_product .product .prod_title .map_text .triangle_control{
        left: 69% !important;
      }
      .rtl .Product_page .brandpage_product .product .prod_title .map_text .triangle_control{
        right: 58% !important;
      }
      .ltr .details .page_product .prod_title .map_text .triangle_control{
        left:63% !important;
      }
      .rtl .details .page_product .prod_title .map_text .triangle_control{
        right:62% !important;
      }
  }