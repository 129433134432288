.triangle-com {
  color: var(--heavenly);
  filter: url("#goo");
  position: absolute;
  transition: 0.5s;
  cursor: pointer;
}
.rtl .triangle-com {
  /* transform: rotate(-90deg); */
}
.ltr .right .triangle-com {
  /* transform: rotate(-180deg); */
  transform: rotateY(180deg);
}
.ltr .left .triangle-com {
  /* transform: rotate(-330deg) !important; */
  transform: rotateY(0) !important;
}
.triangle-before {
  content: '""';
  display: block;
  padding-top: 86%;
  background: currentColor;
  clip-path: polygon(100% 0%, 100% 100%, 0% 50%);
  overflow: hidden;
  background-size: cover;
}
.triangle-control-com-left .triangle-com {
  /* transform: rotate(90deg) !important; */
  transform: rotateY(180deg) !important;
}
.ltr .right .triangle-before {
  margin-right: 10%;
}
.rtl .right.description .triangle-com {
  margin-top: 8rem;
}


/* .triangle-com:hover {
  transform: scale(1.05) !important;
  box-shadow: rgba(255, 255, 255, 0.55);
}

.ltr .right .triangle-com:hover {
  transform: rotateY(180deg) scale(1.05);
}
.ltr .left .triangle-com:hover {
  transform: rotateY(0) scale(1.05) !important;
} */
