.header {
  margin-bottom: -3rem !important;
}
.swiper {
  position: absolute;
  top: -9.4rem !important;
  z-index: 1;
  width: 100%;
  height: 110vh;
  z-index: 1;
  direction: rtl !important;
}
.ltr .head_text{
  width: 90% !important;
  direction: ltr;
}

.header .swiper-vertical > .swiper-pagination-bullets,
.swiper-pagination-vertical.swiper-pagination-bullets {
  right: calc(100% - 1.5rem) !important;
}
.swiper-pagination {
  position: absolute !important;
  left: 0 !important;
}
.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.slide img {
  position: absolute;
  z-index: 11;
}
.slide .slider_arrow {
  font-size: 2rem;
  font-family: 900 !important;
  color: #fff;
}
.rtl .slide .slider_arrow .arrow_l {
  transform: rotate(-135deg);
  cursor: pointer;
}
.ltr .slide .slider_arrow .arrow_l {
  transform: rotate(45deg);
  cursor: pointer;
}
.rtl .slide .slider_arrow .arrow_r {
  transform: rotate(45deg);
  cursor: pointer;
}
.ltr .slide .slider_arrow .arrow_r {
  transform: rotate(-135deg);
  cursor: pointer;
}
.header .head_text {
  position: absolute;
  z-index: 11111;
  color: var(--white);
  text-align: right;
  /* margin-top: 17%; */
  top: 29rem;
  margin-right: 8%;
}

.ltr .header .head_text {
  text-align: left;
  margin-left: 8%;
  margin-right: 0;
}

.header .head_text p {
  font-family: myfont;
  font-size: 16px;
  font-weight: 300;
}
.header .video {
  position: relative;
  top: -7rem;
  left: 0;
  right: 0;
  z-index: 11111;
  border-radius: 4px;
  overflow: hidden;
  width: 70vw;
  height: 60vh;
}

.header .video iframe {
  height: 100%;
  width: 100%;
  position: absolute;
}

.header .video video {
  width: 60vw;
  border-radius: 4px !important;
  z-index: 11;
}
.header .play_video {
  color: var(--white);
  position: absolute;
  z-index: 100000;
  font-size: 4rem;
  cursor: pointer;
  margin-top: 13%;
}

.header .video_slider,
.header .video_slider iframe {
  width: 100%;
  height: 100%;
}

.header .swiper-wrapper {
  transition-timing-function: linear;
}
@media (min-width: 577px) and (max-width: 992px) {
  .header .swiper {
    height: 60vh;
  }

  .header {
    overflow: hidden;
  }
  .header .head_text {
    margin-top: 35% !important;
  }
  .rtl .header .head_text {
    margin-right: 5%;
    font-size: 24px;
  }
  .ltr .header .head_text {
    margin-left: 5%;
  }
  .header .head_text h1 {
    font-size: 24px !important;
  }

  .header .head_text h2 {
    font-size: 22px !important;
  }

  .header .video {
    top: -6rem;
    width: 100%;
    height: 32vh;
  }

  .header .video video {
    width: 100%;
  }
  .header .head_text {
    margin-top: 17%;
    top: 10rem;
    margin-right: 4%;
  }
}

@media screen and (max-width: 768px) {
  .header .swiper {
    height: 50rem;
  }

  .header {
    overflow: hidden;
  }
  .header .head_text {
    margin-top: 35% !important;
  }
  .rtl .header .head_text {
    margin-right: 5%;
    font-size: 24px;
  }
  .ltr .header .head_text {
    margin-left: 5%;
  }
  .header .head_text h1 {
    font-size: 24px !important;
  }

  .header .head_text h2 {
    font-size: 22px !important;
  }

  .header .video {
    top: -6rem;
    width: 100%;
    height: 32vh;
  }

  .header .video video {
    width: 100%;
  }
  .header .head_text {
    margin-top: 17%;
    top: 10rem;
    margin-right: 4%;
  }
}

@media (max-width: 425px) {
  .header .swiper {
    height: 55rem;
  }
  .header .video {
    top: -8.5rem;
    width: 100%;
    height: 26vh;
  }
  .header {
    overflow: hidden;
  }
  .header .head_text {
    margin-top: 60% !important;
  }
  .rtl .header .head_text {
    margin-right: 5%;
    font-size: 22px !important;
  }
  .ltr .header .head_text {
    margin-left: 5%;
  }
  .rtl .header .head_text h4 {
    font-size: 16px !important;
  }
  .ltr .header .head_text h4 {
    font-size: 16px !important;
  }

  .header .head_text h5 {
    font-size: 15px !important;
  }
  .mySwiper .video_slider{
    width: 220vw !important;
  }
}

@media (max-width: 380px) {
  .header .swiper {
    height: 50rem;
  }
  .header .video {
    top: -8.5rem;
    width: 100%;
    height: 31.7vh;
  }
  .header {
    overflow: hidden;
  }
}