.footer {
  background-color: var(--main);
  color: var(--white);
  font-family: myFont;
  overflow: hidden;
}

.footer ul li .triangle {
  position: relative;
  background-color: var(--heavenly);
}
.footer .triangle:before,
.footer .triangle:after {
  content: "";
  position: absolute;
  background-color: inherit;
}
.footer .triangle,
.footer .triangle:before,
.footer .triangle:after {
  width: 0.4em;
  height: 0.4em;
  border-top-right-radius: 40%;
}

.rtl .footer .triangle {
  transform: rotate(-33deg) skewX(-30deg) scale(1, 0.866);
}
.ltr .footer .triangle {
  transform: rotate(-90deg) skewX(-30deg) scale(1, 0.866);
}
.footer .triangle:before {
  transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707)
    translate(0, -50%);
}
.footer .triangle:after {
  transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414) translate(50%);
}

.footer h5 {
  font-weight: 400;
  font-size: 1.3rem;
  padding-bottom: 3%;
}
.rtl .footer .company h5 {
  padding-right: 3%;
}
.ltr .footer h5 {
  padding-left: 12%;
}

.footer h6 {
  font-size: 1.1rem;
}
.footer ul li,
.footer ul li Link {
  font-family: myfont;
  font-weight: 300;
  font-size: 15px;
  padding-bottom: 2px;
}
.rtl .footer ul li,
.rtl .footer ul li Link {
  padding-right: 0;
  padding-left: 4%;
}
.ltr .footer ul li,
.ltr .footer ul li Link {
  padding-right: 0;
  padding-left: 2%;
}
.rtl .footer ul li:hover {
  margin-right: 4%;
  transition: all 0.5s;
}

.footer ul li:hover {
  transition: all 0.5s;
  cursor: pointer;
}
.ltr .footer ul li:hover {
  margin-left: 4%;
  transition: all 0.6s;
}

.footer .arrow_icon {
  color: var(--heavenly);
  font-size: 2rem;
  border-radius: 3px;
  font-weight: 700;
}
.footer .privity_list li {
  display: flex;
  width: 100%;
}
.footer .Social_icon li:hover a {
  transition: all 0.5s;
  font-size: 2.1rem !important;
}
.footer .Social_icon {
  gap: 5%;
}
.footer .Social_icon li a {
  font-size: 1.9rem;
}
.footer .Social_icon li .instagram_icon {
  background-color: var(--white);
  color: var(--main) !important;
  border-radius: 50%;
  padding: 5px;
  font-size: 2.2rem;
}
.footer .line {
  text-align: center;
  margin: auto;
  width: 88vw;
  height: 0.1vh;
  background-color: var(--heavenly);
}
.footer .left {
  display: flex;
  justify-content: end;
  flex-direction: column;
}
.footer p {
  margin: 0;
  font-size: 14px !important;
}
.footer .pe-1 {
  font-size: 11px !important;
}
.footer .footer_logo {
  width: 9vw;
  cursor: pointer;
  text-decoration: none;
}
@media (min-width: 577px) and (max-width: 992px) {
  /* For tablets: */
  .rtl .policty .triangle {
    margin-top: 8% !important;
  }
  .ltr .policty .triangle {
    margin-top: 6% !important;
    margin-right: 4% !important;
  }
  .footer .company h5 {
    font-size: 1.6rem;
    font-weight: 400;
    padding-bottom: 3%;
  }
  .rtl .footer h5 {
    padding-right: 2% !important;
  }
  .ltr .footer h5 {
    padding-left: 5% !important;
  }
  .footer .company a,
  .footer .company li {
    font-size: 18px !important;
    font-weight: 300 !important;
  }
  .ltr .footer .company a,
  .ltr .footer .company li {
    margin-left: -4% !important;
  }

  .footer .triangle,
  .footer .triangle:before,
  .footer .triangle:after {
    width: 0.4em;
    height: 0.4em;
    margin-top: 2% !important;
  }
  .rtl .footer .triangle,
  .rtl .footer .triangle:before,
  .rtl .footer .triangle:after {
    margin-left: 4% !important ;
  }
  .list1 .triangle,
  .list1 .triangle:before,
  .list1 .triangle:after {
    margin-top: 5% !important;
  }
  .rtl .list1 Link {
    padding-right: 2% !important;
  }
  .ltr .list1 Link {
    padding-left: 2% !important;
  }
  .rtl .footer .triangle,
  .rtl .footer .triangle:before,
  .rtl .footer .triangle:after {
    margin-left: 4% !important;
  }
  .ltr .footer .small_call .triangle,
  .ltr .footer .small_call .triangle:before,
  .ltr .footer .small_call .triangle:after {
    margin-right: 8% !important;
  }
  .ltr .footer .triangle,
  .ltr .footer .triangle:before,
  .ltr .footer .triangle:after {
    margin-right: 3% !important;
  }
  .footer .privity_list a {
    font-size: 1.4rem;
  }
  .footer .Social_icon {
    justify-content: center !important;
  }
  .footer .Social_icon li a {
    font-size: 2.8rem;
  }
  .footer .Social_icon li .instagram_icon {
    font-size: 2.8rem;
  }
  .footer .footer-end {
    display: flex;
    justify-content: center !important;
  }
  .footer .left {
    justify-content: center !important;
    margin: auto;
    text-align: center;
  }
  .footer_tola {
    flex-direction: column-reverse;
  }
  .footer_tola h6 {
    font-size: 1.5rem;
  }
  .footer p {
    font-size: 17px;
    padding-bottom: 2% !important;
  }
  .rtl .footer p {
    padding-right: 2% !important;
  }
  .ltr .footer p {
    padding-left: 2% !important;
  }
  .footer .footer_logo {
    margin: auto;
    text-align: center;
    width: 23vw;
  }
  .footer .pe-1 {
    padding-bottom: 0 !important;
    font-size: 1.3rem;
  }
}
@media (max-width: 768px) {
  /* For tablets: */
  .rtl .policty .triangle {
    margin-top: 8% !important;
  }
  .ltr .policty .triangle {
    margin-top: 6% !important;
    margin-right: 4% !important;
  }
  .footer .company h5 {
    font-size: 1.6rem;
    font-weight: 400;
    padding-bottom: 3%;
  }
  .rtl .footer h5 {
    padding-right: 2% !important;
  }
  .ltr .footer h5 {
    padding-left: 5% !important;
  }
  .footer .company a,
  .footer .company li {
    font-size: 18px !important;
    font-weight: 300 !important;
  }
  .ltr .footer .company a,
  .ltr .footer .company li {
    margin-left: -4% !important;
  }

  .footer .triangle,
  .footer .triangle:before,
  .footer .triangle:after {
    width: 0.4em;
    height: 0.4em;
    margin-top: 2% !important;
  }
  .rtl .footer .triangle,
  .rtl .footer .triangle:before,
  .rtl .footer .triangle:after {
    margin-left: 4% !important ;
  }
  .list1 .triangle,
  .list1 .triangle:before,
  .list1 .triangle:after {
    margin-top: 5% !important;
  }
  .rtl .list1 Link {
    padding-right: 2% !important;
  }
  .ltr .list1 Link {
    padding-left: 2% !important;
  }
  .rtl .footer .triangle,
  .rtl .footer .triangle:before,
  .rtl .footer .triangle:after {
    margin-left: 4% !important;
  }
  .ltr .footer .small_call .triangle,
  .ltr .footer .small_call .triangle:before,
  .ltr .footer .small_call .triangle:after {
    margin-right: 8% !important;
  }
  .ltr .footer .triangle,
  .ltr .footer .triangle:before,
  .ltr .footer .triangle:after {
    margin-right: 3% !important;
  }
  .footer .privity_list a {
    font-size: 1.4rem;
  }
  .footer .Social_icon {
    justify-content: center !important;
  }
  .footer .Social_icon li a {
    font-size: 2.8rem;
  }
  .footer .Social_icon li .instagram_icon {
    font-size: 2.8rem;
  }
  .footer .footer-end {
    display: flex;
    justify-content: center !important;
  }
  .footer .left {
    justify-content: center !important;
    margin: auto;
    text-align: center;
  }
  .footer_tola {
    flex-direction: column-reverse;
  }
  .footer_tola h6 {
    font-size: 1.5rem;
  }
  .footer p {
    font-size: 17px;
    padding-bottom: 2% !important;
  }
  .rtl .footer p {
    padding-right: 2% !important;
  }
  .ltr .footer p {
    padding-left: 2% !important;
  }
  .footer .footer_logo {
    margin: auto;
    text-align: center;
    width: 23vw;
  }
  .footer .pe-1 {
    padding-bottom: 0 !important;
    font-size: 1.3rem;
  }
}
@media (max-width: 425px) {
  /* For mobile phones: */
  .footer .company h5 {
    font-size: 1.5rem;
    font-weight: 400;
    padding-bottom: 3%;
  }
  .footer .company li {
    display: flex !important;
    align-items: center;
    gap: 0.5rem;
  }
  .footer .company a,
  .footer .company li {
    font-size: 15px !important;
    font-weight: 300 !important;
    line-height: 30px;
  }
  .footer .privity_list {
    gap: 1%;
  }
  .rtl .ml-3 {
    margin-left: 0.3em;
  }
  .ltr .ml-3 {
    margin-right: 0.3em;
  }
  .footer .triangle,
  .footer .triangle:before,
  .footer .triangle:after {
    width: 0.4em;
    height: 0.4em;
  }
  .footer .privity_list .triangle {
    margin-top: 0.7em !important;
  }
  .rtl .footer .privity_list .triangle {
    margin-left: 0.4em;
  }
  .ltr .footer .privity_list .triangle {
    margin-left: 0.4em;
  }
  .footer .privity_list a {
    font-size: 1.2rem;
  }
  .footer .Social_icon {
    justify-content: center !important;
  }
  .footer .Social_icon li a {
    font-size: 2.4rem;
  }
  .footer .Social_icon li .instagram_icon {
    font-size: 2.6rem;
  }
  .footer .footer-end {
    display: flex;
    justify-content: center !important;
  }
  .footer .left {
    justify-content: center !important;
    margin: auto;
    text-align: center;
  }
  .footer_tola {
    flex-direction: column-reverse;
  }
  .footer_tola p {
    text-align: center !important;
    font-size: 10px !important;
  }
  .footer p {
    font-size: 16px;
    padding-right: 2% !important;
    padding-bottom: 2% !important;
  }
  .rtl .footer p {
    padding-right: 2% !important;
  }
  .ltr .footer p {
    padding-left: 2% !important;
  }

  .footer .footer_logo {
    margin: auto;
    text-align: center;
    width: 38vw;
  }
  .footer .pe-1 {
    padding-bottom: 0 !important;
    font-size: 1.4rem;
  }
}
